<template>
        <date-picker ref="picker"
                     v-model="raw"
                     :lang="{}"
                     :type="type"
                     :clearable="false"
                     :confirm="confirm"
                     :disabled="disabled"
                     :editable="editable"
                     :range="range"
                     :open="open"
                     :time-picker-options="timePickerOptions"
                     @close="onClose"
                     @click.native="onClicked"
                     @blur="open = false"
                     append-to-body>
                     <template v-slot:icon-calendar v-if="hideIcon"><span></span></template>
            <template v-slot:input>
                <b-input-group :size="size" :state="state" style="position:relative">
                    <slot name="prepend"></slot>
                    <b-input :id="id" :placeholder="placeholder" :state="state" v-model="formatted" readonly/>
                    <b-input-group-addon v-if="clearable">
                        <b-button class="clear-button" :variant="hasState && !state ? 'danger' : undefined">
                            <font-awesome-icon class="clear-button" icon="times"/>
                        </b-button>
                    </b-input-group-addon>
                    <slot name="append"></slot>
                </b-input-group>
            </template>
        </date-picker>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import _ from 'underscore';
import {mkDate, date} from '@/util/formatters';

@Component({
    components: {
        DatePicker
    }
})
export default class PickerOfDates extends Vue {
    @Prop({type: String, default: undefined}) id;
    @Prop({type: [Date, Array, String]}) value;
    @Prop({type: String}) type;
    @Prop({type: String, default: 'sm'}) size;
    @Prop({type: Boolean, default: true}) hideIcon;
    @Prop({type: Boolean, default: undefined}) state;
    @Prop({type: String}) placeholder;
    @Prop({type: Boolean, default: true}) clearable;
    @Prop({type: String, default: 'yyyy-MM-dd'}) format;
    @Prop({type: Boolean, default: false}) range;
    @Prop({type: String, default: ' → '}) rangeSeparator;
    @Prop({type: Boolean, default: false}) confirm;
    @Prop({type: Boolean, default: false}) disabled;
    @Prop({type: Boolean, default: true}) editable;
    @Prop({type: Object, default: () => {
        return { start: '00:00', step: '01:00', end: '23:00' };
    }}) timePickerOptions;

    get raw() {
        return this.value;
    }

    set raw(value) {
        this.$emit('input', value);
    }

    get formatted() {
        switch (true) {
            case _.isArray(this.value):
                return _.chain(this.value)
                        .filter(v => !!v)
                        .map(v => date(v, this.format))
                        .value()
                        .join(this.rangeSeparator);

                case _.isDate(this.value):
                    return date(this.value, this.format);

                default:
                    const d = mkDate(this.value);
                    return _.isDate(d) ? date(d, this.format) : '';
        }
    }

    get hasState() {
        return !_.isUndefined(this.state);
    }

    open = false;

    clear() {

        this.open = false;

        switch (true) {
            case this.range:
                this.raw = [null, null];
                break;

            default:
                this.raw = null;
        }
    }

    onClose() {
        this.open = false;
    }



    onClicked(e) {
        const target = e.target;

        if (target.classList.contains('clear-button')) {
            return this.clear();
        }

        const clearButtons = document.querySelectorAll('button.clear-button');

        if (_.any(clearButtons, (b) => _.any(b.children, (c) => c === target || _.any(c.children, (p) => p === target)))) {
            return this.clear();
        }

        if ('INPUT' === target.tagName) {
            this.open = true;
        }
    }
}
</script>
<style scoped xml:lang="scss">
    @import '~vue2-datepicker/index.css';
</style>