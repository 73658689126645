<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Track Export</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="date-range"
                                              label="Track Month/Year Range:">
                                    <b-input-group size="sm">
                                        <PickerOfDates id="date-range"
                                                       type="month"
                                                       format="MMMM yyyy"
                                                       v-model="dateRange"
                                                       input-class="form-control form-control-sm"
                                                       clearable
                                                       range />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import {errorModalOptions} from "../../../util/formatters";
import PickerOfDates from "../../../components/shared/PickerOfDates";

@Component({
    components: {
        PickerOfDates
    }
})
export default class TrackExport extends Vue {
    dateRange = [];

    clearFilters() {
        this.dateRange = [];
    }

    async getCsv() {
        try {
            await download('POST', '/api/v1/export/tracks', JSON.stringify({
                from: this.dateRange[0] || null,
                to: this.dateRange[1] || null
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Track Export', null, true));
    }
}
</script>


<style scoped>
</style>
